import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';

import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import BestSellers from './components/BestSellers';
import Newsletter from './components/Newsletter';
import Shop from './pages/Shop/page';

import Register from './pages/Auth/Register/page';
import Login from './pages/Auth/Login/page';
import EmailVerified from './pages/Auth/Email-Verified/page';
import ProfilePage from './pages/Auth/Profile/page';

import UnderConstruction from './pages/under-construction/page';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';

function App() {
  const isUnderConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION === 'true';
  const location = useLocation();
  const hideLayout = location.pathname === '/under-construction';
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'cookieyes';
    script.type = 'text/javascript';
    script.src = 'https://cdn-cookieyes.com/client_data/64599974b3a2376d7ec3f608/script.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  },[]);

  return (
        <>
          {isUnderConstruction ? (
            <Routes>
              <Route path="*" element={<UnderConstruction />} />
            </Routes>
          ) : (
            <>
              <SpeedInsights />
              <Analytics />
              {!hideLayout && <Header />}

              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Hero />
                      <BestSellers />
                      <Features />
                      <Newsletter />
                    </>
                  }
                />

                {/* Shop Route */}
                <Route path="/shop" element={<Shop />} />

                <Route path="/under-construction" element={<UnderConstruction />} />

                {/* Authentication Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/email-verified" element={<EmailVerified />} />
                <Route path="/profile" element={<ProfilePage />} />
              </Routes>

              {!hideLayout && <Footer />}
            </>
          )}
        </>
  );
}

export default App;
