'use client'

import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Flame, Wind, Sparkles, ShoppingBag, Gem, Star } from 'lucide-react'

const constructionPhrases = [
  "Crafting luxury experiences",
  "Elevating your ambiance",
  "Perfecting every scent",
  "Designing elegance",
  "Coming soon"
]

const icons = [Flame, Wind, Sparkles, ShoppingBag, Gem, Star]

export default function UnderConstruction() {
  const [phraseIndex, setPhraseIndex] = useState(0)
  const [iconIndices, setIconIndices] = useState([0, 1, 2])

  useEffect(() => {
    const phraseInterval = setInterval(() => {
      setPhraseIndex((prevIndex) => (prevIndex + 1) % constructionPhrases.length)
    }, 3000)

    const iconInterval = setInterval(() => {
      setIconIndices(prevIndices => 
        prevIndices.map(() => Math.random() * icons.length | 0)
      )
    }, 2000)

    return () => {
      clearInterval(phraseInterval)
      clearInterval(iconInterval)
    }
  }, [])

  const backgroundVariants = {
    animate: {
      backgroundPosition: ['0% 0%', '100% 100%'],
      transition: {
        duration: 20,
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'reverse' as const
      }
    }
  }

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  }

  const iconVariants = {
    hidden: { scale: 0, rotate: -180 },
    visible: { scale: 1, rotate: 0, transition: { type: 'spring', stiffness: 260, damping: 20 } }
  }

  const sparkles = Array.from({ length: 50 }, (_, i) => (
    <motion.div
      key={i}
      className="absolute w-1 h-1 bg-gray-400 rounded-full"
      style={{
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
      }}
      animate={{
        scale: [0, 1, 0],
        opacity: [0, 0.5, 0],
      }}
      transition={{
        duration: Math.random() * 2 + 1,
        repeat: Infinity,
        repeatDelay: Math.random() * 2,
      }}
    />
  ))

  return (
    <motion.div 
      className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-900 via-gray-800 to-black text-white p-4 overflow-hidden relative"
      variants={backgroundVariants}
      animate="animate"
    >
      {sparkles}
      <motion.div
        className="text-6xl md:text-8xl font-bold mb-8 text-center"
        initial="hidden"
        animate="visible"
        variants={textVariants}
        transition={{ duration: 0.5 }}
      >
        Coming Soon
      </motion.div>

      <motion.div
        className="text-2xl md:text-4xl mb-16 text-center h-12"
        initial="hidden"
        animate="visible"
        variants={textVariants}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <AnimatePresence mode="wait">
          <motion.span
            key={phraseIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            {constructionPhrases[phraseIndex]}
          </motion.span>
        </AnimatePresence>
      </motion.div>

      <div className="flex justify-center space-x-8">
        {iconIndices.map((iconIndex, index) => {
          const IconComponent = icons[iconIndex]
          return (
            <motion.div
              key={index}
              className="w-16 h-16 bg-gray-800 rounded-full flex items-center justify-center"
              variants={iconVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: 0.5 + index * 0.2 }}
            >
              <AnimatePresence mode="wait">
                <motion.div
                  key={iconIndex}
                  initial={{ rotate: -180, opacity: 0 }}
                  animate={{ rotate: 0, opacity: 1 }}
                  exit={{ rotate: 180, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <IconComponent className="w-10 h-10 text-gray-200" />
                </motion.div>
              </AnimatePresence>
            </motion.div>
          )
        })}
      </div>

      <motion.div
        className="mt-16 text-xl text-gray-300 text-center px-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 1 }}
      >
        Luxury diffusers and candles, coming to elevate your space
      </motion.div>
    </motion.div>
  )
}